import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "display"
    }}>{`Display`}</h1>
    <p>{`CSS has `}<inlineCode parentName="p">{`display`}</inlineCode>{` rule classes`}</p>
    <p>{`As such, the classes are named using the format:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`display-{value} for xs`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`display-{breakpoint}-{value} for sm, md, lg, xl, and xxl.`}</inlineCode></li>
    </ul>
    <p>{`Where value is one of:`}</p>
    <pre><code parentName="pre" {...{}}>{`none
inline
inline-block
block
grid
table
table-cell
table-row
flex
inline-flex
`}</code></pre>
    <h2 {...{
      "id": "examples"
    }}>{`Examples`}</h2>
    <p className="display-flex">I`m a flex paragraph</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<p className="display-flex">
  ...
</p>
`}</code></pre>
    <p className="display-sm-flex">
  I`m a grid paragraph only when it is smaller than sm breakpoint
    </p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<p className="display-sm-flex">
  ...
</p>
`}</code></pre>
    <h3 {...{
      "id": "visibility-hidden-force"
    }}>{`Visibility Hidden Force`}</h3>
    <p>{`Apply a `}<inlineCode parentName="p">{`visibility: hidden !important;`}</inlineCode>{` rule to an element to visually hide it but keep it accessible to screen readers.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<p className="visibility-hidden-force">
  ...
</p>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      